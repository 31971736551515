/**
 * @generated SignedSource<<a970e52f4acd6643888400b80251a2b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SiteLocale = "en_GB" | "sv_SE" | "%future added value";
export type HelpCenterTopicPageQuery$variables = {
  locale?: SiteLocale | null | undefined;
  topicUrl: string;
};
export type HelpCenterTopicPageQuery$data = {
  readonly topic: {
    readonly description: string | null | undefined;
    readonly id: string;
    readonly name: string | null | undefined;
    readonly url: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"Breadcrumb_topic">;
  } | null | undefined;
};
export type HelpCenterTopicPageQuery = {
  response: HelpCenterTopicPageQuery$data;
  variables: HelpCenterTopicPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topicUrl"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "topicUrl"
          }
        ],
        "kind": "ObjectValue",
        "name": "url"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HelpCenterTopicPageQuery",
    "selections": [
      {
        "alias": "topic",
        "args": (v2/*: any*/),
        "concreteType": "HelpCenterTopicRecord",
        "kind": "LinkedField",
        "name": "helpCenterTopic",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Breadcrumb_topic"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HelpCenterTopicPageQuery",
    "selections": [
      {
        "alias": "topic",
        "args": (v2/*: any*/),
        "concreteType": "HelpCenterTopicRecord",
        "kind": "LinkedField",
        "name": "helpCenterTopic",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14f6434dab30ac275bc4dc08fe773772",
    "id": null,
    "metadata": {},
    "name": "HelpCenterTopicPageQuery",
    "operationKind": "query",
    "text": "query HelpCenterTopicPageQuery(\n  $topicUrl: String!\n  $locale: SiteLocale\n) {\n  topic: helpCenterTopic(locale: $locale, filter: {url: {eq: $topicUrl}}) {\n    id\n    name\n    description\n    url\n    ...Breadcrumb_topic\n  }\n}\n\nfragment Breadcrumb_topic on HelpCenterTopicRecord {\n  id\n  name\n  url\n}\n"
  }
};
})();

(node as any).hash = "4cb8abf3bc9b8ffd410ad2fa4c6d53b8";

export default node;
