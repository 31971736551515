/**
 * @generated SignedSource<<8208c70d94193a22278b5259aba0c704>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopBar_team$data = {
  readonly path: string;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioBreadcrumbs_team">;
  readonly " $fragmentType": "TopBar_team";
};
export type TopBar_team$key = {
  readonly " $data"?: TopBar_team$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopBar_team">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopBar_team",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioBreadcrumbs_team"
    }
  ],
  "type": "ProjectMeasuring_Team",
  "abstractKey": null
};

(node as any).hash = "8980592b4fc701e127b8f580bf78aebf";

export default node;
