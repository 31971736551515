/**
 * @generated SignedSource<<3421c878aea4c66c6e5dceb8f094ee66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopBar_pqiUser$data = {
  readonly authUser: {
    readonly " $fragmentSpreads": FragmentRefs<"ProfilePopper_user">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioBreadcrumbs_pqiUser">;
  readonly " $fragmentType": "TopBar_pqiUser";
};
export type TopBar_pqiUser$key = {
  readonly " $data"?: TopBar_pqiUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopBar_pqiUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopBar_pqiUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "authUser",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfilePopper_user"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioBreadcrumbs_pqiUser"
    }
  ],
  "type": "PQiUser",
  "abstractKey": null
};

(node as any).hash = "a1a914cab02978752f06bd266af2447f";

export default node;
