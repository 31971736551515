/**
 * @generated SignedSource<<4717d245610f4c97090a56a586f47ebd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeadingRecord_content$data = {
  readonly headerText: string | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "HeadingRecord_content";
};
export type HeadingRecord_content$key = {
  readonly " $data"?: HeadingRecord_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeadingRecord_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeadingRecord_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headerText",
      "storageKey": null
    }
  ],
  "type": "HeadingRecord",
  "abstractKey": null
};

(node as any).hash = "b87d25f9d5ca6845c580384562441a86";

export default node;
