/**
 * @generated SignedSource<<34a2161e43230e41945ca6bf56b29620>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticleContentOld_content$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleLinkRecord_content" | "HeadingRecord_content" | "ImageRecord_content" | "PreambleRecord_content" | "TextRecord_content">;
  readonly " $fragmentType": "ArticleContentOld_content";
};
export type ArticleContentOld_content$key = {
  readonly " $data"?: ArticleContentOld_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticleContentOld_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleContentOld_content",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TextRecord_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeadingRecord_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ImageRecord_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreambleRecord_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticleLinkRecord_content"
    }
  ],
  "type": "HelpCenterArticleModelContentField",
  "abstractKey": "__isHelpCenterArticleModelContentField"
};

(node as any).hash = "50c30792b8a2392171c04db7e3b74ad3";

export default node;
