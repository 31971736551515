/**
 * @generated SignedSource<<dc225e960f53a897bdc8d11c87645cee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Breadcrumb_topic$data = {
  readonly id: string;
  readonly name: string | null | undefined;
  readonly url: string | null | undefined;
  readonly " $fragmentType": "Breadcrumb_topic";
};
export type Breadcrumb_topic$key = {
  readonly " $data"?: Breadcrumb_topic$data;
  readonly " $fragmentSpreads": FragmentRefs<"Breadcrumb_topic">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Breadcrumb_topic",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "HelpCenterTopicRecord",
  "abstractKey": null
};

(node as any).hash = "c7d0eafdcb2b711d34b1aee1ba01bc1e";

export default node;
