/**
 * @generated SignedSource<<4b883b8c682c4145864ac438161718ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamPageLayoutRouteQuery$variables = {
  teamPath: string;
};
export type TeamPageLayoutRouteQuery$data = {
  readonly pqiUser: {
    readonly " $fragmentSpreads": FragmentRefs<"MainMenu_pqiUser" | "TopBar_pqiUser" | "useOnlyMyProjects_pqiUser">;
  } | null | undefined;
  readonly team: {
    readonly " $fragmentSpreads": FragmentRefs<"MainMenu_team" | "TopBar_team" | "useOnlyMyProjects_team">;
  } | null | undefined;
};
export type TeamPageLayoutRouteQuery = {
  response: TeamPageLayoutRouteQuery$data;
  variables: TeamPageLayoutRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "teamPath"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "path",
    "variableName": "teamPath"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamPageLayoutRouteQuery",
    "selections": [
      {
        "alias": "team",
        "args": (v1/*: any*/),
        "concreteType": "ProjectMeasuring_Team",
        "kind": "LinkedField",
        "name": "projectMeasuring_teamByPath",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TopBar_team"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MainMenu_team"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useOnlyMyProjects_team"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PQiUser",
        "kind": "LinkedField",
        "name": "pqiUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MainMenu_pqiUser"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TopBar_pqiUser"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useOnlyMyProjects_pqiUser"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamPageLayoutRouteQuery",
    "selections": [
      {
        "alias": "team",
        "args": (v1/*: any*/),
        "concreteType": "ProjectMeasuring_Team",
        "kind": "LinkedField",
        "name": "projectMeasuring_teamByPath",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProjectMeasuring_MainPortfolio",
            "kind": "LinkedField",
            "name": "mainPortfolio",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectMeasuring_SubPortfolio",
                "kind": "LinkedField",
                "name": "subPortfolios",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "parent",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PQiUser",
        "kind": "LinkedField",
        "name": "pqiUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PQiUserGrant",
            "kind": "LinkedField",
            "name": "grants",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "teamId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "portfolioId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "authUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emailAddress",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa3082eafdb82011b087e96d29b740b2",
    "id": null,
    "metadata": {},
    "name": "TeamPageLayoutRouteQuery",
    "operationKind": "query",
    "text": "query TeamPageLayoutRouteQuery(\n  $teamPath: String!\n) {\n  team: projectMeasuring_teamByPath(path: $teamPath) {\n    ...TopBar_team\n    ...MainMenu_team\n    ...useOnlyMyProjects_team\n    id\n  }\n  pqiUser {\n    ...MainMenu_pqiUser\n    ...TopBar_pqiUser\n    ...useOnlyMyProjects_pqiUser\n    id\n  }\n}\n\nfragment MainMenu_pqiUser on PQiUser {\n  grants {\n    teamId\n    portfolioId\n    projectId\n    role\n  }\n  authUser {\n    emailAddress\n    id\n  }\n}\n\nfragment MainMenu_team on ProjectMeasuring_Team {\n  id\n}\n\nfragment PortfolioBreadcrumbs_pqiUser on PQiUser {\n  ...usePortfolioList_pqiUser\n}\n\nfragment PortfolioBreadcrumbs_team on ProjectMeasuring_Team {\n  name\n  path\n  id\n  ...usePortfolioList_team\n}\n\nfragment ProfilePopper_user on User {\n  id\n  fullName\n  emailAddress\n}\n\nfragment TopBar_pqiUser on PQiUser {\n  authUser {\n    ...ProfilePopper_user\n    id\n  }\n  ...PortfolioBreadcrumbs_pqiUser\n}\n\nfragment TopBar_team on ProjectMeasuring_Team {\n  path\n  ...PortfolioBreadcrumbs_team\n}\n\nfragment useOnlyMyProjects_pqiUser on PQiUser {\n  grants {\n    teamId\n    portfolioId\n    projectId\n    role\n  }\n}\n\nfragment useOnlyMyProjects_team on ProjectMeasuring_Team {\n  id\n  path\n}\n\nfragment usePortfolioList_pqiUser on PQiUser {\n  grants {\n    teamId\n    portfolioId\n    projectId\n    role\n  }\n}\n\nfragment usePortfolioList_team on ProjectMeasuring_Team {\n  id\n  path\n  name\n  mainPortfolio {\n    id\n    subPortfolios {\n      id\n      path\n      name\n      parent {\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea7e914152b67eea98afa434cb92bc22";

export default node;
